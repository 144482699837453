import { gsap, ScrollTrigger } from "gsap/all";
import { qs, qsa } from "../../resources/js/utils/dom";
gsap.registerPlugin(ScrollTrigger);

const container = qs(".fixed-scroll"),  
  animationLenght = qs(".fixed-scroll-content-inner", container).offsetHeight + qs('header').offsetHeight,
  isMobile = window.matchMedia('(max-width: 1023px)').matches;
  //isSmallerScreen = window.matchMedia('(max-width: 1440px)').matches,
  //zoomRatio = window.devicePixelRatio === 1 ? window.devicePixelRatio : window.devicePixelRatio > 1 ? 1 - (window.devicePixelRatio - 1) : 1 + (1 - window.devicePixelRatio);

//screen lock
const scrollBar = gsap.timeline().to('.scroll-lock', { y: () => { return animationLenght }, ease: "none" });
ScrollTrigger.create({
  trigger: container,
  start: '-' + qs('header').offsetHeight + 'px',
  end: "+=" + animationLenght,
  pin: true,
  pinSpacing: true,
  anticipatePin: 1,
  scrub: 0.5,
  toggleActions: "play none none reverse",
  animation: scrollBar,
});

//green columns slide in
const slideIn = gsap.to('.fixed-scroll-content-inner', {
  yPercent: -100,
  y: qs('.fixed-scroll--item:last-child', container).offsetHeight, // do not scroll last item
  scrollTrigger: {
    trigger: container,
    start: 'bottom bottom',
    scrub: 0.5,
    end: '+=' + animationLenght,
    toggleActions: 'play none none reverse',
  },
});

qsa('.fixed-scroll--image').slice(1).forEach((item) => { //skip 1 item
    const colAnimationStart = isMobile ? container.offsetHeight + 32 : qs(".fixed-scroll-title", container).offsetHeight + qs('header').offsetHeight + qs(item.dataset.content).previousElementSibling.offsetHeight / 2;
    gsap.timeline().to(item, {
    scrollTrigger: {
      trigger: item.dataset.content,
      start:'top ' + colAnimationStart,
      duration: 3,
      scrub: 0.5,
      toggleActions: "play none none reverse",
      animationConatiner: slideIn,
      onEnter: () => {
        item.classList.add('active')
        qs(item.dataset.content).classList.add('active')
      },
      onLeaveBack: () => {
        item.classList.remove('active')
        qs(item.dataset.content).classList.remove('active')
      },
    },
  });
});
